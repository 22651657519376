import React from "react";

const buttonVariants = {
  white: "bg-white text-blue-primary hover:text-navy-dark",
  "white-secondary": "bg-transparent text-white border border-white",
  blue: "bg-blue-primary text-white hover:bg-blue-dark",
  "blue-secondary":
    "bg-transparent text-blue-primary border border-blue-primary hover:bg-blue-primary hover:text-white",
  green:
    "bg-green-primary text-navy-dark font-neue-medium border border-green-primary",
  "green-secondary":
    "bg-transparent text-green-primary border border-green-primary font-neue-medium",
  navy: "bg-navy text-white font-neue-medium border border-navy",
  "navy-dark": "bg-navy-dark text-white font-neue-medium",
  "gray-outline": "text-gray-dark border border-gray-light font-neue-medium",
  "transparent-blue": `bg-white text-sky-blue font-medium whitespace-nowrap
    transition-all duration-300 hover:bg-slate-50 shadow-[0px_1px_3px_0px_#2463E01A,0px_0px_0px_1px_#2463E014,0px_1px_1px_0px_#2463E01F]`,
  "sky-blue": `whitespace-nowrap text-lg font-medium text-white bg-sky-blue
      bg-[radial-gradient(100%_100%_at_50%_0%,rgba(255,255,255,0.3)_0%,rgba(255,255,255,0)_100%)]
      border border-sky-blue rounded-xl shadow-[0px_0px_0px_1px_#2970FF] hover:opacity-80
      transition-opacity duration-300`,
  "dark-gradient": `whitespace-nowrap text-lg font-medium text-white bg-black
      bg-[radial-gradient(100%_100%_at_50%_0%,rgba(255,_255,_255,_0.3)_0%,rgba(255,_255,_255,_0)_100%)]
      border border-sky-blue rounded-xl shadow-[0px_0px_0px_1px_#000000] hover:opacity-80
      transition-opacity duration-300 border-none`,
};
const buttonSizes = {
  unset: "",
  big: "h-14 px-5",
};

const Button = ({
  variant = "green",
  children,
  size = "unset",
  className = "",
  ...props
}) => {
  return (
    <button
      className={`rounded-full py-3.5 px-6 text-base font-medium flex items-center justify-center 
        transition-all duration-300 leading-tight
        ${buttonVariants[variant]} ${buttonSizes[size]} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
