import outpatientInpatientSrc from "../assets/outpatient-inpatient.png";
import patientCaregiversSrc from "../assets/patient-caregivers.png";
import telehealthEncountersSrc from "../assets/telehealth-encounters.png";
import clientTestimonialTwoAvatarSrc from "../assets/testimonials/harold-cordner.png";
import clientTestimonialOneAvatarSrc from "../assets/testimonials/madetric-wood.svg";
import clientTestimonialFourAvatarSrc from "../assets/testimonials/margaret-smith.svg";
import clientTestimonialFiveAvatarSrc from "../assets/testimonials/shirin-peters.svg";
import clientTestimonialThreeAvatarSrc from "../assets/testimonials/stanley-dao.png";

export const Pages = {
  HOME: "/",
  TRY_SCRIBE: "/TryScribe",
  CARE_MANAGEMENT: "/care-management",
  SCRIBE: "/scribe",
  ADMINISTRATIVE: "/administrative",
  CASE_STUDIES: "/case-studies",
  BETHANY_MEDICAL: "/case-studies/bethany-medical-clinic",
  CONTACT: "/contact",
  PRIVACY: "/privacy-notice",
  TERMS: "/terms-of-use",
  DISCLAIMER: "/disclaimer",
  THANK_YOU: "/thank-you",
  TRY_FRONT_DESK: "/tryfrontdesk",
};

export const useCasesLinks = [
  { label: "Care Management", link: Pages.CARE_MANAGEMENT },
  { label: "Scribe", link: Pages.SCRIBE },
  { label: "Administrative", link: Pages.ADMINISTRATIVE },
];

export const companyLinks = [
  { label: "Blog", link: "https://blog.quadrant.health" },
  { label: "Request demo", link: Pages.CONTACT },
  { label: "Case studies", link: Pages.CASE_STUDIES },
];

// 1. CEO: The primary decision-makers for the company.
// 2. Medical Director: A physician who provides guidance and leadership on the use of medicine within the company.
// 3. Physician: Medical professionals who may use or influence the use of your products or services.
// 4. Engineer: Professionals who work on the technical aspects of your product or infrastructure.
// 5. Practice Manager: Individuals who manage the operations of a medical practice.
// 6. Data Analyst: Professionals handling the company’s health data analysis.
// 7. Sales Representative: Team members who sell the startup’s products or services.
// 8. Marketing Manager: Individuals managing the company’s marketing efforts.
// 9. Other: *** Free text ****

export const roles = [
  { label: "CEO", value: "CEO" },
  { label: "Medical Director", value: "Medical Director" },
  { label: "Physician", value: "Physician" },
  { label: "Engineer", value: "Engineer" },
  { label: "Practice Manager", value: "Practice Manager" },
  { label: "Data Analyst", value: "Data Analyst" },
  { label: "Sales Representative", value: "Sales Representative" },
  { label: "Marketing Manager", value: "Marketing Manager" },
  { label: "Other", value: "Other" },
];

export const requestDemoLink = "https://w93jwghrztm.typeform.com/to/P03yE6eF";

// privacy notice, terms, disclaimer styles
export const headerStyle = "scroll-mt-32 font-semibold";
export const blockStile = "mb-8 space-y-4";
export const listStyle = "list-disc ml-8";
export const linkStyle = "inline-block text-blue-primary hover:underline";
export const tdStyle = "p-2";

export const landingNoteTemplatesTabs = [
  {
    title: "Multiple Sections",
    sections: [
      {
        title: "Chief Complaint",
        values: [
          "Primary Complaint: Cough and shortness of breath",
          "Duration: 5 days",
        ],
      },
      {
        title: "History of Present Illness",
        values: [
          "Onset: Symptoms started 5 days ago",
          "Location: Chest",
          "Duration: Persistent, throughout the day",
          "Character: Dry, hacking cough with intermittent sharp chest pain",
          "Aggravating Factors: Physical activity, cold air",
          "Relieving Factors: Rest, warm fluids",
          "Timing: Worse in the evening and night",
          "Severity: 6/10",
        ],
      },
    ],
  },
  {
    title: "SOAP",
    sections: [
      {
        title: "Subjective",
        values: [
          "Chief complaint: Persistent headaches for 2 weeks",
          "Associated symptoms: Nausea, sensitivity to light",
          "Pain description: Throbbing, right-sided, 7/10 intensity",
        ],
      },
      {
        title: "Objective",
        values: [
          "Neurological exam: Cranial nerves intact, no focal deficits",
          "Head and neck: Tenderness over right temporalis muscle",
          "Severity: 6/10",
        ],
      },
      {
        title: "Assessment and Plan",
        values: [
          `Based on the patient's presentation, acute bronchitis is the primary differential diagnosis,
            but pneumonia must be ruled out. A chest X-ray will be ordered to exclude pneumonia.
            Treatment will include a bronchodilator prescription to alleviate respiratory symptoms.
            The patient is advised to rest and increase fluid intake. If symptoms persist or worsen,
            a follow-up appointment is recommended in 3-5 days for re-evaluation and potential adjustment
            of the treatment plan.`,
        ],
      },
    ],
  },
  {
    title: "Procedure",
    sections: [
      {
        title: "Procedure Performed",
        values: [
          "Left shoulder arthroscopy",
          "Rotator cuff repair",
          "Subacromial decompression",
        ],
      },
      {
        title: "Preoperative Diagnosis",
        values: ["Left rotator cuff tear", "Subacromial impingement syndrome"],
      },
      {
        title: "Operative Findings",
        values: [
          "Full-thickness supraspinatus tendon tear, approximately 2 cm",
          "Moderate subacromial bursitis",
          "Type II acromion with impingement on rotator cuff",
        ],
      },
    ],
  },
];

export const landingAppFeaturesTitles = {
  NOTE_TEMPLATES: "Personalized Note Templates",
  FORMS: "Automatically fill out forms",
  NORMALS_NEGATIVES: "Automatically populate normals and negatives",
  ICD_CODES: "Automatically generate relevant ICD-10 codes",
  SPEECH_RECOGNITION: "Multilingual Speech Recognition for Healthcare",
};

export const landingAppFeatures = [
  {
    title: landingAppFeaturesTitles.NOTE_TEMPLATES,
    description:
      "Over 100+ options to personalize clinical notes. Customize formatting, sections, style to fit your workflow and speciality.",
  },
  {
    title: landingAppFeaturesTitles.FORMS,
    description:
      "Fully complete any form (independently or alongside a clinical note) like Medicare Annual Visits, PHQ-9s, and Sick notes. Never fill out a form again.",
  },
  {
    title: landingAppFeaturesTitles.NORMALS_NEGATIVES,
    description:
      "Spend less time repeating the same information. Customize your notes to auto-populate normal and negative findings.",
  },
  {
    title: landingAppFeaturesTitles.ICD_CODES,
    description:
      "Automatically generate relevant ICD-10 codes within the note. Improve your HCC code capture while reducing work.",
  },
  {
    title: landingAppFeaturesTitles.SPEECH_RECOGNITION,
    description:
      "Quadrant Health AI is trained on over 10 million patient conversations. It supports over 50 languages and filters out background noise.",
  },
];

export const testimonials = [
  {
    author: "Madetric Wood",
    position: ", NP",
    company: "Geriatric Solutions",
    text: "Thank you for giving my life back! At the end of the day, my documentation is done without any hassle.",
    avatarSrc: clientTestimonialOneAvatarSrc,
  },
  {
    author: "Harold Cordner",
    position: ", MD",
    company: "Florida Pain Management",
    text: "I am happily surprised with how well the Quadrant Health AI Scribe has worked in our practice. For the first time in a long time I have been able to close my notes daily and leave work on time with minimal effort. I have used a human scribe for many years, but I must say the detail in the notes is actually better and more compliant. I save about three or four hours per day with better documentation",
    avatarSrc: clientTestimonialTwoAvatarSrc,
  },
  {
    author: "Stanley Dao",
    position: ", MD, CM, MBA",
    company: "Centre Médical Mieux-Être",
    text: "Using Quadrant Health has been incredibly positive. It’s easy to use and automatically picks up and translates when my patients are switching between French or English. It's shifted the way I think about care management and patient intake. Since I'm spending less time documenting, I can fully concentrate on my patients.",
    avatarSrc: clientTestimonialThreeAvatarSrc,
  },
  {
    author: "Margaret Smith",
    position: "",
    company: "Operations Director, Stanford",
    text: "Having worked with many health technology companies, Quadrant Health's expertise in Al and ability to adapt in a rapidly changing field sets them apart.",
    avatarSrc: clientTestimonialFourAvatarSrc,
  },
];

export const landingAllSettings = [
  {
    title: "Patient and Caregivers",
    alt: "Nurse and middle-aged patient both smiling at each other",
    imgSrc: patientCaregiversSrc,
  },
  {
    title: "Outpatient and Inpatient",
    alt: "A middle-aged woman wearing a mask sits in a chair, facing a nurse, who is also wearing a mask. The nurse holds a form for collecting patient information.",
    imgSrc: outpatientInpatientSrc,
  },
  {
    title: "Telehealth Encounters",
    alt: "A middle-aged man engaged in a video call with a doctor.",
    imgSrc: telehealthEncountersSrc,
  },
];

export const pricingPlanName = {
  SCRIBE_LITE: "Scribe Lite",
  SCRIBE_PRO: "Scribe Pro",
  ENTERPRISE: "Enterprise",
};

export const pricingPlans = [
  {
    name: pricingPlanName.SCRIBE_LITE,
    price: "Free",
    description: "No Credit Card Required",
    features: [
      "20 uses per month",
      "ICD-10 Codes",
      "Multi-language support",
      "Custom templates",
    ],
  },
  {
    name: pricingPlanName.SCRIBE_PRO,
    price: "$120",
    priceWithDiscount: "$60",
    description: "First Month Discount",
    features: ["Unlimited uses per month", "Add Normals", "Forms", "Teams"],
  },
  {
    name: pricingPlanName.ENTERPRISE,
    price: "Contact us",
    features: [
      "Everything in Pro, plus",
      "EHR integrations",
      "Patient Context",
      "Scribe Calls",
      "AI Front Desk",
      "Priority support",
    ],
  },
];

export const frequentlyAskedQuestions = [
  {
    question: "What is Quadrant Health's AI medical scribe?",
    answer: `Quadrant Health's AI medical scribe is an advanced tool that uses proprietary
      large medical language models to generate accurate medical dictations, transcriptions,
      and chart notes. It can transcribe your voice and conversations with patients into detailed
      medical notes, which can be used to generate various outputs like SOAP notes, letters, and
      forms based on templates.`,
  },
  {
    question: "How does the scribe work?",
    answer: `Simply speak into the app, and it will transcribe your voice into accurate
      and detailed medical notes. The AI scribe can evaluate the entire transcription context
      to effectively eliminate errors.`,
  },
  {
    question: "How accurate are the transcriptions?",
    answer: `Our transcription model is over 99% accurate. Our proprietary large
      language model (LLM) evaluates the entire transcription context to effectively eliminate
      errors and suppress background noise. `,
  },
  {
    question: "Does this work for all medical specialties?",
    answer: `Yes, our AI scribe is trained on encounters from over 35 specialties.
      We offer over 30 pre-made templates, and you can customize sections to fit your specific needs.`,
  },
  {
    question: "Can I use dictation, dot phrases, and macros?",
    answer: `Absolutely! Our AI scribe supports dictation, dot phrases, and macros.
      You can even use voice triggers for dot phrases and macros, streamlining your workflow.`,
  },
  {
    question:
      "How does Quadrant AI handle pre-populated normals and form filling?",
    answer: `You can add forms and pre-completed notes to our system. The AI detects any
      discrepancies between the form/note and the encounter and updates the note accordingly.
      If nothing is mentioned about a pre-populated section, it remains unchanged. For forms,
      you can specify where the AI is allowed to fill out and what choices it has. We will not
      modify anything you don't want us to. `,
  },
  {
    question: "Is my data secure and private?",
    answer: `At Quadrant Health, we take data security and privacy extremely seriously.
      All data is end-to-end encrypted on the user's device. We have implemented strong security
      measures to ensure the confidentiality and integrity of your data. We are fully HIPAA, SOC2,
      and GDPR compliant, and we never share data with third parties. We own the entire data pipeline,
      ensuring every note created is securely stored. You have the ability to clear all data at any time.`,
  },
  {
    question: "Can it integrate with my existing EMR system?",
    answer: `For our free and pro versions, we don't offer direct EMR integration, but our
      Chrome extension can be used on top of your EMR for easy copy-and-paste functionality. For
      enterprise clients, please contact us to discuss full integration options with your EMR.`,
  },
  {
    question: "What if I encounter technical issues or need support?",
    answer: `If you experience any technical issues or require assistance, please reach out to
      us by emailing support@quadrant.health. We'll promptly address your concerns and provide the
      necessary guidance.`,
  },
];
