import React, { lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { Pages } from "./utils/constants";
import ScrollToTop from "./components/Layout/ScrollToTop";
import Layout from "./components/Layout";

import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";

const Home = lazy(() => import("./pages/Home"));
const TryScribe = lazy(() => import("./pages/TryScribe"));
const ChronicCareManagement = lazy(() =>
  import("./pages/UseCases/ChronicCareManagement")
);
const CaseStudies = lazy(() => import("./pages/CaseStudies"));
const BethanyMedicalClinic = lazy(() => import("./pages/BethanyMedicalClinic"));
const Contact = lazy(() => import("./pages/Contact"));
const Privacy = lazy(() => import("./pages/Privacy"));
const Terms = lazy(() => import("./pages/Terms"));
const Disclaimer = lazy(() => import("./pages/Disclaimer"));
const Scribe = lazy(() => import("./pages/UseCases/Scribe"));
const Administrative = lazy(() => import("./pages/UseCases/Administrative"));
const ThankYou = lazy(() => import("./pages/ThankYou"));
const TryFrontDesk = lazy(() => import("./pages/TryFrontDesk"));

const App = () => {
  return (
    <ScrollToTop>
      <Layout>
        <Suspense fallback={<div className="h-screen" />}>
          <Routes>
            <Route path={Pages.HOME} element={<Home />} />
            <Route path={Pages.TRY_SCRIBE} element={<TryScribe />} />
            <Route
              path={Pages.CARE_MANAGEMENT}
              element={<ChronicCareManagement />}
            />
            <Route path={Pages.SCRIBE} element={<Scribe />} />
            <Route path={Pages.ADMINISTRATIVE} element={<Administrative />} />
            <Route path={Pages.CASE_STUDIES} element={<CaseStudies />} />
            <Route
              path={Pages.BETHANY_MEDICAL}
              element={<BethanyMedicalClinic />}
            />
            <Route path={Pages.CONTACT} element={<Contact />} />
            <Route path={Pages.PRIVACY} element={<Privacy />} />
            <Route path={Pages.TERMS} element={<Terms />} />
            <Route path={Pages.DISCLAIMER} element={<Disclaimer />} />
            <Route path={Pages.THANK_YOU} element={<ThankYou />} />
            <Route path={Pages.TRY_FRONT_DESK} element={<TryFrontDesk />} />
            <Route path="*" element={<Navigate to={Pages.HOME} replace />} />
          </Routes>
        </Suspense>
      </Layout>
      <Analytics />
      <SpeedInsights />
    </ScrollToTop>
  );
};

export default App;
